import { EditarFormularioComponent } from './page/editar-formulario/editar-formulario.component';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule  } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './theme/shared/shared.module';
//import { NgxEditorModule } from 'ngx-editor';


import { AppComponent } from './app.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { NavigationComponent } from './theme/layout/admin/navigation/navigation.component';
import { NavContentComponent } from './theme/layout/admin/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavBarComponent } from './theme/layout/admin/nav-bar/nav-bar.component';
import { NavLeftComponent } from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { NavRightComponent } from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import {ChatUserListComponent} from './theme/layout/admin/nav-bar/nav-right/chat-user-list/chat-user-list.component';
import {FriendComponent} from './theme/layout/admin/nav-bar/nav-right/chat-user-list/friend/friend.component';
import {ChatMsgComponent} from './theme/layout/admin/nav-bar/nav-right/chat-msg/chat-msg.component';
import { ConfigurationComponent } from './theme/layout/admin/configuration/configuration.component';
import {NgxSimpleTextEditorModule} from 'ngx-simple-text-editor';
import { ToggleFullScreenDirective } from './theme/shared/full-screen/toggle-full-screen';
import {ArchwizardModule} from 'angular-archwizard';

/* Menu Items */
import { NavigationItem } from './theme/layout/admin/navigation/navigation';
import { NgbAccordionModule, NgbButtonsModule, NgbCollapseModule, NgbDropdownModule, NgbPaginationModule, NgbTabsetModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './page/login/login.component';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { ToastrModule } from 'ngx-toastr';
import {TextMaskModule} from 'angular2-text-mask';
import {NgNumberFormatterModule} from 'ng-number-formatter';

import { FullTextSearchPipe  } from './shared/filter.pipe';
import { SanitizeHtmlPipe  } from './shared/sanitize.pipe';
import { LoggedInGuard } from './page/security/loggedin.quard';
import { NgxSpinnerModule } from 'ngx-spinner';
import {ColorPickerModule} from 'ngx-color-picker';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './page/home/home.component';
import { GComponent } from './page/g/g.component';
import {TinymceModule} from 'angular2-tinymce';
import { EditComponent } from './page/edit/edit.component';
import { CadastroUsuarioComponent } from './page/cadastro-usuario/cadastro-usuario.component';
import { ConfigComponent } from './page/config/config.component';
import { EstatisticaComponent } from './page/estatistica/estatistica.component';
import { SelectModule } from 'ng-select';
import {TagInputModule} from 'ngx-chips';
import { CadastroTriagemComponent } from './page/cadastro-triagem/cadastro-triagem.component';
import {DragulaModule, DragulaService} from 'ng2-dragula';
import { ProgramaComponent } from './page/programa/programa.component';
import { SelectOptionService } from './theme/shared/components/select/select-option.service';
import { PesquisaTriagemComponent } from './page/pesquisa-triagem/pesquisa-triagem.component';
import { ResultadoComponent } from './page/resultado/resultado.component';
import { DetalheResultadoTriagemComponent } from './page/detalhe-resultado-triagem/detalhe-resultado-triagem.component';
import { BarRatingModule } from 'ngx-bar-rating';
import { CadastroTriagemNovoComponent } from './page/cadastro-triagem/cadastro-triagem-novo/cadastro-triagem-novo.component';
import { CadastroQuestoesComponent } from './page/cadastro-questoes/cadastro-questoes.component';
import { PesquisaQuestoesComponent } from './page/pesquisa-questoes/pesquisa-questoes.component';
import { CadastroGrupoQuestoesComponent } from './page/cadastro-grupo-questoes/cadastro-grupo-questoes.component';
import { CadastroTurmaComponent } from './page/cadastro-turma/cadastro-turma.component';
import { VisualizarPerguntasComponent } from './page/visualizar-perguntas/visualizar-perguntas.component';
import { CadastroParticipanteComponent } from './page/cadastro-participante/cadastro-participante.component';
import { CadastroResponsavelComponent } from './page/cadastro-responsavel/cadastro-responsavel.component';
import { EditarTriagemComponent } from './page/editar-triagem/editar-triagem.component';
import { EditarQuestaoComponent } from './page/editar-questao/editar-questao.component';
import { DetalheResultadoAprovadoComponent } from './page/detalhe-resultado-aprovado/detalhe-resultado-aprovado.component';
import { AvisoComponent } from './page/aviso/aviso.component';
import { CadastroMidiaComponent } from './page/cadastro-midia/cadastro-midia.component';
import { DataTablesModule } from 'angular-datatables';
import { MinhaTabelaComponent } from './page/minha-tabela/minha-tabela.component';
import { HomeProgramaComponent } from './page/home-programa/home-programa.component';
import { CadastroFormularioComponent } from './page/cadastro-formulario/cadastro-formulario.component';
import { CadastroPerguntaFormularioComponent } from './page/cadastro-pergunta-formulario/cadastro-pergunta-formulario.component';
import { PesquisaFormularioComponent } from './page/pesquisa-formulario/pesquisa-formulario.component';
import { PesquisaPerguntaComponent } from './page/pesquisa-pergunta/pesquisa-pergunta.component';
import { EditarPerguntaComponent } from './page/editar-pergunta/editar-pergunta.component';
import { CadastroModulosProgramaComponent } from './page/cadastro-modulos-programa/cadastro-modulos-programa.component';
import { ModalCadastroModulosComponent } from './page/modal-cadastro-modulos/modal-cadastro-modulos.component';
import { ModalAddFormularioComponent } from './page/modal-add-formulario/modal-add-formulario.component';
import { ModalAddEtapaComponent } from './page/modal-add-etapa/modal-add-etapa.component';
import { FormsModule } from '@angular/forms';
import { CadastroDiarioComponent } from './page/cadastro-diario/cadastro-diario.component';
import { ModalCadastroDiarioComponent } from './page/modal-cadastro-diario/modal-cadastro-diario.component';
import { AdicionaDiarioEtapaComponent } from './page/adiciona-diario-etapa/adiciona-diario-etapa.component';
import { PushNotificationComponent } from './page/push-notification/push-notification.component';
import { SuporteComponent } from './page/suporte/suporte.component';
import { OrderByPipe } from './shared/orderBy.pipe';
import { MeetingComponent } from './page/meeting/meeting.component';

//import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
//import {getDatabase, provideDatabase } from '@angular/fire/database';
//import { environment } from 'src/environments/environment';



//import { AdvRatingComponent } from './demo/ui-elements/ui-adv/adv-rating/adv-rating.component';


@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ChatUserListComponent,
    FriendComponent,
    ChatMsgComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,
    LoginComponent,
    HomeComponent,
    FullTextSearchPipe,
    SanitizeHtmlPipe,
    OrderByPipe,
    GComponent,
    EditComponent,
    CadastroUsuarioComponent,
    ConfigComponent,
    EstatisticaComponent,
    CadastroTriagemComponent,
    ProgramaComponent,
    PesquisaTriagemComponent,
    ResultadoComponent,
    DetalheResultadoTriagemComponent,
    CadastroTriagemNovoComponent,
    CadastroQuestoesComponent,
    PesquisaQuestoesComponent,
    CadastroGrupoQuestoesComponent,
    CadastroTurmaComponent,
    VisualizarPerguntasComponent,
    CadastroParticipanteComponent,
    CadastroResponsavelComponent,
    EditarTriagemComponent,
    EditarQuestaoComponent,
    DetalheResultadoAprovadoComponent,
    AvisoComponent,
    CadastroMidiaComponent,
    MinhaTabelaComponent,
    HomeProgramaComponent,
    CadastroFormularioComponent,
    CadastroPerguntaFormularioComponent,
    PesquisaFormularioComponent,
    EditarFormularioComponent,
    PesquisaPerguntaComponent,
    EditarPerguntaComponent,
    CadastroModulosProgramaComponent,
    ModalCadastroModulosComponent,
    ModalAddFormularioComponent,
    ModalAddEtapaComponent,
    CadastroDiarioComponent,
    ModalCadastroDiarioComponent,
    AdicionaDiarioEtapaComponent,
    PushNotificationComponent,
    SuporteComponent,
    MeetingComponent,



  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule ,
    SharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbTabsetModule,
    FileUploadModule,
    NgxSpinnerModule,
    NgbCollapseModule,
    NgbAccordionModule,
    ArchwizardModule,
    TextMaskModule,
    ColorPickerModule,
    TinymceModule,
    SelectModule,
    TagInputModule,
    NgNumberFormatterModule,
    BarRatingModule,
    NgbPaginationModule,
    DataTablesModule,
    FormsModule,
    //NgxEditorModule,
    NgxSimpleTextEditorModule,
    DragulaModule.forRoot(),
    ToastrModule.forRoot(), // ToastrModule added
    //provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    //provideDatabase(() => getDatabase()),


  ],
  providers: [NavigationItem, LoggedInGuard, DragulaService, SelectOptionService],
  bootstrap: [AppComponent]
})
export class AppModule { }
